<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="container fn text-white">
      <div>
        <div>
          <span>
            <router-link :to="{ name: 'dashboard-hitler' }">
              <i class="fad fa-angle-double-left fa-2x" />
            </router-link>
          </span>
        </div>
        <b-row class="mb-1 mt-1">
          <b-col
            v-for="item in prolist"
            :key="item.id"
            md="6"
            cols="12"
            class="mt-2"
          >
            <div>
              <b-img
                :src="item.pic"
                fluid
                class="img_pro"
              />

              <div class="mt-1 p-1">
                <h5 style="color: #042b52">
                  {{ item.name }}
                </h5>
                <small style="margin-top: -10px; color: #042b52; font-size: 1rem">
                  {{ item.detail }}
                </small>
                <br>
                <small style="margin-top: -10px; color: #ee7522; font-size: 1rem">
                  หมายเหตุ ::
                </small>
                <br>
                <small style="margin-top: -10px; color: #042b52; font-size: 1rem">
                  {{ item.remark }}
                </small>
                <br>
                <small style="margin-top: -10px; color: #042b52; font-size: 1rem">
                  รับโบนัส  {{ item.percent }}%  สูงสุด {{ item.max_bonus }} บาท โดยต้องทำเทิร์น {{ item.turn }} เทิร์น  ถอนได้สูงสุด {{ item.max_withdraw }} บาท
                </small>
                <div class="demo-inline-spacing mt-1">
                  <button
                    block
                    class="m-0 btn"
                    @click="getbetflixpro(item.id)"
                  >
                    รับโบนัส
                  </button>
                </div>

              </div>
            </div>
          </b-col>
        </b-row>

        <hr class="mt-2">
        <!-- <div class="bg_select">
        <b-form-radio
          v-model="Selected"
          name="some-radio9"
          value="g"
          class="custom-control-success"
        >
          ไม่รับรับโบนัสอัตโนมัติ
        </b-form-radio>
      </div> -->
        <br><br>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BOverlay,
  BIconController,
  // BFormRadio,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BOverlay,
    BIconController,
    // BFormRadio,
  },
  data() {
    return {
      show: true,
      selected: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      prolist: [],
    }
  },
  mounted() {
    this.getUser(this.userData)
    this.getProlist()
  },
  methods: {
    getUser(userData) {
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/users/show/${userData.userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.show = false
          this.User = response.data
        })
        .catch(error => console.log(error))
    },
    getbetflixpro(id) {
      if (id === 12) {
        this.$router.push({ name: 'invite-friend' })
        return
      }
      if (id === 2) {
        this.$router.push({ name: 'fixed-deposit' })
        return
      }
      this.show = true
      this.$http
        .get(`https://api.ufabangkok.com/api/promotion/getufapro/${id}`)
        .then(response => {
          this.show = false
          this.Success(response.data.message)
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    getProlist() {
      this.show = true
      this.$http
        .get('https://api.ufabangkok.com/api/promotion/prolists')
        .then(response => {
          this.show = false
          this.prolist = response.data
          // this.prolist = response.data.filter(ele => ele.id === 1 || ele.id === 6)
          // console.log(response.data)
        })
        .catch(
          error => this.SwalError(error.response.data.message),
          (this.show = false),
        )
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'SUCCESS',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 540px;
}
.bg_select {
  background-color: #0b0e11;
  padding: 15px 15px;
  border-radius: 35px;
}
.img_pro {
  border-radius: 20px;
}
.btn {
  border-radius: 16px;
  width: 100vw;
  color: #fff !important;
  background-color: #ee7522;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
a {
  color: #fff;
}
i {
  color: rgb(255, 255, 255);
}
/* asdasd */
</style>
